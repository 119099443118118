import React from "react";
import "./App.css";
function refundpolicy() {
    return (
        <div className="refundPolicy">
            <h1>Cancellation and Refund Policy</h1>
            <h4>Cancellation & Refund Charges:</h4>
            <p>
                Our cancellation policy purely works on status of bill payment.
                irrespective of payment priority, we charge 2.5% flat
                cancellation & refund charges, when you use our services as
                registered user.
            </p>
            <h4>Cancellation & Refund Terms:</h4>
            <p>
                Cancellation is only possible if payment settlement from our
                payment gateway / bank has not taken place.Once the settlement
                has been initiated by our Bankers / Online Payment system, we
                will not be able to cancel the transaction request.
            </p>
            <h4>Terms of Refund Policy :</h4>
            <p>
                Rules for Refund and Claims Claims will be settled, and refund
                will be automated within 5-7 working days of the transaction
                cancellation. Maximum 5-7 working days is the time frame for the
                user-initiated refund claims.In case of system failure or any
                technical error, automated refund will be initiated by system
                itself and amount will be credited within 5-7 working day (As
                per bank).In worst to worst if you face any problem in getting
                refund, please share a request on support@paygen.in or
                9866592901. we will surely attend your concerns within shortest
                possible time.
            </p>
        </div>
    );
}

export default refundpolicy;
